_f = require('lodash/fp')

module.exports = class SalonFormCtrl
  constructor: (PromptService, GetMatchesService, RESTService, SalonTypesService) ->
    'ngInject'
    @PromptService = PromptService
    @getMatchesService = GetMatchesService
    @RESTService = RESTService
    @SalonTypesService = SalonTypesService

  $onInit: ->
    @updateBranchioLink = @updateBranchioLink.bind(@)
    @RESTService.one('admin/supported_countries').get()
      .then (response) =>
        @countries = response.data.countries
        if @salon.country_id
          @country = _f.find(id: @salon.country_id)(@countries)
          @selectedCity = _f.find(id: @salon.city_id)(@country.cities)

    @initialHours = _.cloneDeep(@salon.hours)
    @salonTypesDefinition = @SalonTypesService(@salon)

  showHomeLeadTime: ->
    @salonTypesDefinition.isDefault() || @salonTypesDefinition.inHomeOnly()

  showLeadTime: ->
    @salonTypesDefinition.isDefault() || @salonTypesDefinition.inSalonOnly()

  setSalonCountryId: ->
    @salon.country_id = @country?.id

  setSalonCityId: ->
    @salon.city_id = @selectedCity?.id

  matchedCities: (query) ->
    @getMatchesService.call(@country.cities, query, 'name.en')

  showVATBoolean: ->
    @country && parseFloat(@country.value_added_tax_percent) > 0

  copyReferalUrlToClipboard: ->
    copyText = document.querySelector("#referal_url");
    copyText.select();
    document.execCommand("Copy");

  updateBranchioLinkButtonText: ->
    if @salon.branchio_link
      "Update Branch.io Link"
    else
      "Create Branch.io Link"

  updateBranchioLink: ->
    @onBranchioLinkUpdateButtonClick()

  copyBranchioLinkToClipboard: ->
    copyText = document.querySelector("#branchio_link");
    copyText.select();
    document.execCommand("Copy");

  submit: ->
    if @salon.id && !_.isEqual(@initialHours, @salon.hours)
      @$$workingHoursSubmit()
    else
      @$$submitSalon()

  $$workingHoursSubmit: ->
    @PromptService.show(textContent:
      'You going to update all salon therapists working hours. Are you sure?')
    .then =>
      @$$submitSalon()

  $$submitSalon: ->
    @onSubmit(salon: @salon)
