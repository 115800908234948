_ = require('lodash')

module.exports = ->
  restrict: 'E'
  scope:
    model: '='
    services: '='
  template: require('./index.pug')()
  link: (scope) ->
    scope.service_ids = if scope.model.service_ids
      _.reduce(
        scope.model.service_ids,
        ((acc, id) ->
          acc[id] = true
          acc),
        {})
    else
      _.reduce(scope.model.services, ((acc, elem) ->
        acc[elem.id] = true
        acc
      ), {})

    scope.$watch 'service_ids', (->
      scope.model.service_ids =
        _.reduce(scope.service_ids, ((acc, val, key) ->
          acc.push(key) if val
          acc), [])
    ), true

